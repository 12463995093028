<template>
  <section
    id="smart-factory-solutions"
  >
    <br>
    <br>
    <br>
    <base-section-heading
      icon="mdi-factory"
      title="AUTOMATION SOLUTION"
    />
    <v-tabs
      v-model="tab"
      centered
      show-arrows
    >
      <v-tab
        v-for="(t, i) in tabs"
        :key="i"
        :href="`#${t.toLowerCase()}`"
        class="text-capitalize"
      >
        {{ t }}
      </v-tab>
    </v-tabs>
    <br>
    <br>
    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="(project, i) in projects"
          :key="project.title"
          class="gallery-card"
          cols="12"
        >
          <base-left-photo-two
            :src="project.src"
            :subheading="project.title"
            :heading="project.subtitle"
            :html="project.html"
          >
          </base-left-photo-two>
        </v-col>
      </v-fade-transition>
    </v-container>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionSmartFactorySolutions',
    data: () => ({
      tab: 'All',
      tabs: [
        'All',
        'Storage',
        'Picking',
        'Distribution',
        'Robot',
        'Scanner',
      ],
    }),
    computed: {
      projects () {
        return [
          { title: 'Stacker Crane', subtitle: 'Storage', html: 'Stacker Crane is an AS/RS (Automated Storage and Retrival System) that picks up and releases tote boxes of Pcs/Box units containing the requested order using a number of shuttles arranged on each floor. Worker Goods-to-Person (GTP) station can be established to link with handling/forwarding work.', src: require('@/assets/shuttle_rack.jpg') },
          { title: 'Shuttle', subtitle: 'Storage', html: 'Shuttle is an AS/RS (Automated Storage and Retrial System) that picks up and releases tote boxes of Pcs/Box units containing the requested order items with shuttle cranes moving forward/back and down on a single rail. Worker Goods-to-Person (GTP) stations can be established to work with handling/forwarding operations.', src: require('@/assets/miniload.jpg') },
          { title: 'Pallet Automatic Warehouse', subtitle: 'Storage', html: 'Pallet Automatic Warehouse is an AS/RS (Automated Storage and Retrival System) in which cranes that move forward/back, up/down on a single rail pick up the pallet containing the requested order and release it. The conveyor linkage allows the pallet to be transported to the destination.', src: require('@/assets/palletautomaticwarehouse.jpg') },
          { title: 'DPS', subtitle: 'Picking', html: 'Digital Picking System (DPS) is a solution that helps you quickly and accurately pick up by displaying the quantity of products to be picked on a digital indicator based on the order information entered in the WMS.', src: require('@/assets/dps.jpg') },
          { title: 'DAS', subtitle: 'Picking', html: 'Based on the order information entered in the WMS, DAS (Digital Assortment System) is a solution that displays the quantity of products to be distributed in each direction on a digital indicator and puts the products in a number of tote boxes to help you distribute them quickly and accurately.', src: require('@/assets/das.jpg') },
          { title: 'Conveyor', subtitle: 'Distribution', html: 'Conveyor is a logistics infrastructure that supports the transport of goods from one place to another. Conveyors in the form of rollers and belts are popular and can be driven manually and electrically depending on the type.', src: require('@/assets/conveyor.jpg') },
          { title: 'Sorter', subtitle: 'Distribution', html: 'Sorter is a facility that classifies products that need to be distributed by region and by customer. We use a socket that fits the characteristics of the product we handle and the throughput requirements, and typically provide Tilt Tray, Cross Belt, Slide Shoe, and Wheel Sorter.', src: require('@/assets/sorter.jpg') },
          { title: 'Vertical Carrier', subtitle: 'Distribution', html: 'Vertical carrier is a technology that improves logistics flow by transporting goods and tote boxes vertically to increase the efficiency of inter-floor logistics movement.', src: require('@/assets/vertical_carrier.jpg') },
          { title: 'AGV', subtitle: 'Robot', html: 'AGV Solution is a Goods-to-Person (GTP) solution based on shelf rack transport via AGV, which transports the shelf rack where the requested product is stored to the worker, and the worker picks and ships it.', src: require('@/assets/agv.jpg') },
          { title: 'Arc Welding', subtitle: 'Robot', html: 'The arc welding robot provides a standard interface that can be integrated with the welding power source, and it also has a starting point sensing and touch sensing to recognize the correct position of the welding.', src: require('@/assets/main_desc-1.jpg') },
          { title: '3D vision scanner', subtitle: 'Scanner', html: 'The 3D vision scanner is a solution that deploys multiple scanners to enable product barcode recognition in many ways.', src: require('@/assets/3dvisionscanner.jpg') },
        ].filter(project => {
          if (this.tab.toLowerCase() === 'all') return true

          return project.subtitle.toLowerCase() === this.tab.toLowerCase()
        })
      },
    },
  }
</script>
